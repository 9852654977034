<template>
  <div
    class="vue-tour-popover-content"
    ref="PopContent"
    tabindex="-1"
    @keydown.enter="proceedToNextStep"
    @keydown.esc="closePopover"
  >
    <div class="vue-tour-popover-content__header">
      <p>Welcome to Vue Tour!</p>
    </div>
    {{ currentDescription }}
    <div class="vue-tour-popover-content__actions">
      <button
        class="vue-tour-popover-content__button"
        :class="{ hidden: step === 0 }"
        @click="backToPreviousStep"
      >
        Previous
      </button>

      <button
        class="vue-tour-popover-content__button"
        @click="proceedToNextStep"
      >
        {{ nextButtonLabel }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      descriptions: [
        'Vue is Awesome!',
        '#1 - Component data must be a function',
        '#2 - Prop definitions should be as detailed as possible',
        '#3 - Always use key with v-for',
        '#4 - Never use v-if on the same element as v-for'
      ],
      step: 0
    }
  },

  computed: {
    currentDescription() {
      return this.descriptions[this.step]
    },

    isLastStep() {
      return this.step === this.descriptions.length - 1
    },

    nextButtonLabel() {
      return this.isLastStep ? 'Finish tour' : 'Next'
    }
  },

  methods: {
    backToPreviousStep() {
      this.step--
    },

    closePopover() {
      this.$emit('closePopover')
    },

    proceedToNextStep() {
      if (this.isLastStep) {
        this.closePopover()
      } else {
        this.step++
      }
    }
  },

  mounted() {
    this.$refs.PopContent.focus()
  }
}
</script>

<style lang="css" scoped></style>
