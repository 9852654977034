<template>
  <div class="base-popover-content">
    <div class="base-popover-content__arrow" x-arrow />
    <div class="base-popover-content__body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BasePopoverContent'
}
</script>

<style lang="scss" scoped></style>
