<template>
  <div class="interface">
    <figure>
      <figcaption>visuals from version 0.1.15 (June 2020)</figcaption>
      <img
        src="../assets/img/interface3.png"
        alt="visuals from version 0.1.15"
        width
        height
        border="0"
      />
    </figure>

    <figure>
      <figcaption>mock up visuals (2019)</figcaption>
      <img
        src="../assets/img/interface.png"
        alt="visualisation of the future spatial
    interface"
        width
        height
        border="0"
      />
      <img
        src="../assets/img/interface2.png"
        alt="visualisation of the future spatial interface"
        width
        height
        border="0"
      />
    </figure>
  </div>
</template>

<script>
export default {
  name: 'Interface'
}
</script>

<style lang="scss" scoped></style>
