<template>
  <div class="home">
    <MainContent @openPopover="openPopover()"></MainContent>
    <Interface></Interface>
    <BasePopover
      v-if="isPopoverVisible"
      :popover-options="popoverOptions"
      @closePopover="closePopover"
    >
      <BasePopoverContent>
        <PopContent @closePopover="isPopoverVisible = false" />
      </BasePopoverContent>
    </BasePopover>
  </div>
</template>

<script>
// @ is an alias to /src
import MainContent from '@/components/MainContent.vue'
import Interface from '@/components/Interface.vue'
import BasePopover from '@/components/BasePopover.vue'
import BasePopoverContent from '@/components/BasePopoverContent.vue'
import PopContent from '@/components/PopContent.vue'

export default {
  name: 'home',
  components: {
    MainContent,
    Interface,
    BasePopover,
    BasePopoverContent,
    PopContent
  },

  data() {
    return {
      isPopoverVisible: false,
      popoverOptions: {
        popoverReference: null,
        placement: 'top',
        offset: '0,0'
      }
    }
  },

  mounted() {
    this.popoverOptions.popoverReference = this.$refs.popoverReference
  },

  methods: {
    closePopover() {
      this.isPopoverVisible = false
    },

    openPopover() {
      this.isPopoverVisible = true
    }
  }
}
</script>
