<template>
  <div class="explain">
    <img
      src="../assets/img/brand.png"
      alt="brand image update for nodenogg.in 2021"
    />

    <figure>
      <figcaption>mockup visuals (2020)</figcaption>
      <img src="../assets/img/interface4.jpg" alt="mockup visuals (2020)" />
    </figure>
    <h1>nodenogg.in <span class="tip">- follow the purple links</span></h1>

    <h2>
      🚀
      <a href="https://alpha.nodenogg.in">try now!</a>
      <span class="tip"> (alpha build)</span>
    </h2>
    <p>
      nodenogg.in is free / libre open source software, built in the open,
      inclusive by design, private by design, humane centred by design,
      delightful by design, intuitive and decentralised by design.
    </p>

    <p>
      Built with 💛 using open web tech HTML, CSS, Javascript, SVG/Pixijs,
      Vue.js, PouchDB and CouchDB.
    </p>
    <p>
      nodenogg.in is a <strong>messy</strong> serendipitous
      <strong>co-creation</strong> space to <strong>play</strong> and
      <strong>tinker</strong> around with ideas together (in realtime).
    </p>
    <p>
      nodenogg.in is designed to support a new type of co-creation, a messy
      spatial realtime digital canvas where you and your fellow makers can build
      ideas and connect knowledge.
    </p>
    <h2>
      👩‍👧‍👧
      <a href="/#/community">join community</a>
      <span class="tip"> (discuss and debate)</span>
    </h2>
    <p>
      join the nodenogg.in community, we have a discord to chat ideas, a forum
      to debate ideas and features and of course you can contribute to code.
    </p>

    <h2>
      🚀
      <a href="https://alpha.nodenogg.in">launch app</a>
      <span class="tip"> (alpha build)</span>
    </h2>

    <p>
      nodenogg.in allows the creation of nodes by a group of designers &amp;
      makers working together within a shared realtime digital canvas. This is
      designed to be used as an extension of the physical co-working space. The
      nodes are created, edited and arranged spatially in realtime together
      allowing connections and annotations to be drawn between and around them.
      This persistent and shared spatial interface provides an opportunity to
      cluster and gather thoughts providing new insight and routes through
      thinking on your design problem or research theme you are investigating.
      Try it now on your own or better still with co-creators
      <a href="https://alpha.nodenogg.in">launch</a> (alpha build).
    </p>

    <h2>
      👍
      <a href="https://www.youtube.com/channel/UCiFY1PKloMcquwuOoWmWTwg"
        >YouTube</a
      >
      <span class="tip"> (development and guides)</span>
    </h2>
    <p>Watch insight and development videos and help guides.</p>
    <h2>
      👏
      <a href="https://monzo.me/adamprocter/20.00">instant donate</a
      ><span class="tip"> (£20 secure monzo payment)</span>
    </h2>
    <p>Show your instant support with a one off donation to the project.</p>
    <h2>
      💰
      <a href="https://www.patreon.com/procterbot">support</a> nodenogg.in
      <span class="tip">(patron monthly)</span>
    </h2>
    <p>
      Need details on why Adam Procter is building nodenogg.in 🤔 ? Then start
      with this academic
      <a
        href="https://manifold.soton.ac.uk/read/untitled-11c75cfd-a6a6-4918-9e69-2a960cebdf09/section/e7d8df1b-00bf-44c2-9e06-f0bbfe34f4e9"
        >document</a
      >
      and then this
      <a
        href="https://manifold.soton.ac.uk/read/untitled-162d4fb0-8ab1-422c-aef4-0f00a2ef0d74/section/c735fb9e-a5a2-4063-bbc5-0b96c78896a0"
        >document</a
      >.
    </p>

    <h2>
      🦄
      <a href="/feed/subs.opml">follow</a> the project
      <span class="subline">
        (
        <a href="https://ranchero.com/netnewswire/">rss reader</a>
        needed)
      </span>
    </h2>
    <figure>
      <figcaption>visuals from version 0.0.12 (sept 2019)</figcaption>
      <img
        src="../assets/img/nn.png"
        alt="interface image from version 0.0.12d"
      />
    </figure>
  </div>
</template>

<script>
export default {
  name: 'MainContent',

  methods: {
    openPopover() {
      this.$emit('openPopover')
    },
  },
}
</script>

<style lang="css" scoped>
.tip {
  font-size: 0.8em;
  color: grey;
}
</style>
